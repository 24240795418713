import { Trans } from '@lingui/macro'
import { darken } from 'polished'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import Row from '../Row'

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 4px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  background: #171722;
  border-radius: 6px;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  font-weight: 500;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  width: 124px;
  height: 40px;
  line-height: 40px;
  justify-content: center;
  &.${activeClassName} {
    background: #303044;
    border-radius: 6px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

export default function HeaderTab() {
  return (
    <HeaderLinks>
      <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
        <Trans>Swap</Trans>
      </StyledNavLink>
      <StyledNavLink
        id={`pool-nav-link`}
        to={'/pool'}
        isActive={(match, { pathname }) =>
          Boolean(match) ||
          pathname.startsWith('/add') ||
          pathname.startsWith('/remove') ||
          pathname.startsWith('/increase') ||
          pathname.startsWith('/find')
        }
      >
        <Trans>Pool</Trans>
      </StyledNavLink>
    </HeaderLinks>
  )
}
